import Header from "./common/Header";
import Footer from "./common/Footer";
import { Button, Col, Form, Modal, Row,Card,Accordion, } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Country, State, City, getAllCountries } from "country-state-city";
import { userService } from "../services";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    geocodeByPlaceId,
} from "react-places-autocomplete";
import { config } from "../config/config";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Health_app = () => {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  // const queryParams = new URLSearchParams(window.location.search);
	// const queryheader = queryParams.get('header')
    let preview;
    const [showHide, setShowHide] = useState(false);
    const [allQuizes, setAllQuizes] = useState([]);
    const [selectedQuizId, setSelectedQuizId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isUserLogin, setIsUserLogin] = useState(false);
    const [isPlayedFirstTime, setIsPlayedFirstTime] = useState(false);
    const [city, setCity] = useState('');
    const [offers, setOffers] = useState([]);
    const user_id = localStorage.getItem('user_id');
    const [testimonial, setTestiMonial] = useState("")
    const [currentVideoIndex, setCurrentVideoIndex] = useState(-1);
    const [address, setAddress] = useState("");
    const [quizdata, setQuizData] = useState("")
    const quizSectionRef = useRef(null); 
    const [userDetail, setuserDetails] = useState({ email: "", name: "", PhoneNumber: "", companyName: "", country: "", service: [""], message: "" })

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        QuizList()
    }, [])

    const urlLocation = useLocation();
    const queryParams = new URLSearchParams(urlLocation.search);
    const queryheader = queryParams.get('header')
    const quiz = queryParams.get('quiz');
    quizSectionRef.current =quiz;
    const quizListElement = document.getElementById("Quizlist");
    useEffect(() => {
         if(quiz){
          if (quizListElement) {
            quizListElement.scrollIntoView({ behavior: 'smooth' });
          }

         }
        
    }, [quiz, quizListElement]); // Depend on the "quiz" value so that the effect runs when "quiz" changes
    const [service, setservice]=useState(false)


    useEffect(() => {
        setIsUserLogin(user_id ? true : false);
        getAllQuizes("Sahibzada Ajit Singh Nagar");
        setCurrentLocation();
        getOffers();
    }, []);

    const [serviceOther, setserviceOther]=useState("")

    const handleInputChange = (event) => {
        const { value } = event.target;
        setserviceOther(value)
      };
    const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      let updatedService;
  
      if (checked) {
        updatedService = [...userDetail.service, value];
      } else {
        updatedService = userDetail.service.filter((item) => item !== value);
      }
      setuserDetails((prevState) => ({
        ...prevState,
        service: updatedService,
      }));
    };

      const handleSubmit2 = () => {
        let temp =[...userDetail.service];
        temp[0]=serviceOther;

        setuserDetails((prevState) => ({
          ...prevState,
          service: temp, 
        }));
        setservice(true)
        setShow(false)
      };
    


    function getOffers() {
        setIsLoading(true);
        userService.getOffers().then((res) => {
            if (res.status === 200) {
                setIsLoading(false);
                setOffers(res.data.offers);
            }
        }).catch((err) => {

        });
    }

    function setCurrentLocation() {
        if (navigator.geolocation) {
            setIsLoading(true);
            navigator.geolocation.getCurrentPosition(showPosition, error_location);
        } else {
        }
    }

    function showPosition(position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;

        userService.getCurrentLocation(lat, lng).then((response) => {
            let city = response.data.results[0].address_components[0].long_name;
            getAllQuizes(city);
            setCity(city)
        }).catch((error) => {
            setIsLoading(false);
        });
    }

    function error_location(err) {
        setIsLoading(false);
        if (err.message == "User denied Geolocation") {
        }
        if (err.code == 2 || err.code == "2") {
        }
        getAllQuizes("");
    }

    function getAllQuizes(city) {
        setIsLoading(true);
        userService.getQuizes(city).then((response) => {
            setIsLoading(false);
            if (response.data.status == 200) {
                let quizesData = response.data.data;
                setAllQuizes(quizesData);
                if (quizesData[0].quiz_done_status == 'COM' || quizesData[0].quiz_done_status == 'QUIT') {
                    setIsPlayedFirstTime(true);
                }
            } else {
                toast.error("Some Error Occur");
            }
        }).catch((error) => {
            setIsLoading(false);
            setAllQuizes([]);
        });
    }

    function handleModalShowHide(id, status) {
        if (user_id) {
            if (status === 'NEW') {
                setSelectedQuizId(id);
                setShowHide(!showHide);
            } else if (status === 'QUIT') {
                toast.warning("Quiz forfeited")
            } else if (status === 'COM') {
                window.location.href = "/result?id=" + id;
            } else {
                toast.error("Something Went Wrong");
            }
        } else {
            setShowHide(!showHide);
        }
    }

    function startQuiz() {
        if (localStorage.getItem('user_id')) {
            localStorage.setItem('done', false);
            window.location.href = "/quiz?id=" + selectedQuizId;
        } else {
            toast.error("Please sign-up before to get participate into quiz")
        }
    }

    function goToOffer(id) {
        id ? window.location.href = window.location.origin + '/product_details?id=' + id : window.location.href = window.location.origin;
    }



    function QuizList() {
        userService.quizList().then((res) => {
            if (res?.data?.status === 200) {
                setQuizData(res?.data?.data)
            }
        })
            .catch((error) => {
            })
    }

    function inputChange(e) {
        let { name, value } = e.target;
        setuserDetails({ ...userDetail, [name]: value });
    }

    // const handleClearText = () => {
    //   if(service == false){
    //     setuserDetails({
    //       service: [""],
    //     });
    //   }
    // }
    const handleClearText = () => {
      if(service == false){
        setuserDetails({ ...userDetail,
          service: [""],
        });
      }
    }

    function handleSumit(e) {
        e.preventDefault();
        const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
        if (!userDetail.name || userDetail?.name.trimStart() == "") {
            Swal.fire("Warning", "Name field can’t be empty.", "warning")
            return
        }
        else if (!userDetail.email || userDetail?.email.trimStart() == "") {
            Swal.fire("Warning", "E-mail field can’t be empty.", "warning")
            return
        }
        else if (!EMAILREGEX.test(userDetail.email)) {
            Swal.fire("Warning", 'Entered E-mail address is not valid.', "warning")
            return
        }
        else if (!userDetail.PhoneNumber || userDetail?.PhoneNumber.trimStart() == "") {
            Swal.fire("Warning", 'Phone Number field can’t be empty.', "warning")
            return
        }
        else if (!userDetail.companyName || userDetail?.companyName.trimStart() == "") {
            Swal.fire("Warning", 'Company Name field can’t be empty.', "warning")
            return
        }
        else if (!userDetail?.country || userDetail?.country.trimStart() == "") {
            Swal.fire("Warning", 'country field can’t be empty.', "warning")
            return
        }
        // else if (!userDetail?.service) {
        //     Swal.fire("Warning", 'service field can’t be empty.', "warning")
        //     return
        // }
        else if (!userDetail?.service?.filter(item => item)?.length) {
          Swal.fire("Warning", 'service field can’t be empty.', "warning")
          return
        }
        else if (!userDetail?.message || userDetail?.message.trimStart() == "") {
            Swal.fire("Warning", 'message field can’t be empty.', "warning")
            return
        }
        else {
            contactus()
        }
    }

    function contactus() {
        const params = {
            name: userDetail.name,
            email: userDetail?.email,
            companyName: userDetail?.companyName,
            country: userDetail?.country,
            PhoneNumber: userDetail?.PhoneNumber,
            service:userDetail?.service,
            message: userDetail?.message,
            page_type: "healthApp"
        }
        userService.contactUs(params).then((res) => {
            if (res?.data?.status === 200) {
                Swal.fire("Success", res?.data?.message, "success").then((res) => {
                  setuserDetails({
                    email: "",
                    name: "",
                    PhoneNumber: "",
                    companyName: "",
                    country: "",
                    service: [""],
                    message: ""
                  });
                })
                setuserDetails({
                  email: "",
                  name: "",
                  PhoneNumber: "",
                  companyName: "",
                  country: "",
                  service: [""],
                  message: ""
                });
            }
        })
            .catch((error) => {
            })
    }

    const handleSelect = async (valueOfLocation, placeId) => {
        const results = await geocodeByAddress(valueOfLocation);
        const latLng = await getLatLng(results[0]);
        setAddress(valueOfLocation)
        const [place] = await geocodeByPlaceId(placeId);
        const { long_name: countryselect = '' } = place.address_components.find(c => c.types.includes('country')) || {};
        const { long_name: selCity = '' } = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || {};
        Country.getAllCountries().forEach(item => {
            if (countryselect == item.name) {
            }
        })
    }

    const onVideoPlay = (item) => {
        setTestiMonial(prev =>
            prev.map((value) =>
                value._id == item._id ? { ...value, videoPlay: true } : { ...value, videoPlay: false }
            )
        )
    }

    const onVideoPause = (item) => {
        setTestiMonial(prev =>
            prev.map((value) =>
                value._id == item._id ? { ...value, videoPlay: false } : { ...value, videoPlay: false }
            )
        )
    }

    return (
        <>
          {queryheader !== "false" &&  <Header />}
            <section className="health-app-banner">
                <div className="container">
                    <Row className="align-items-center">
                        <Col md={12} lg={6}>
                            <div className="health-app-text">
                                <h1>Let's build a plan<br /> to stop the burden of chronic diseases.</h1>
                                <h5>Get the only 12-in-one health apps to engage patients and employees.</h5>
                                <p>Hospitals, insurance companies, and businesses get the complete solution for chronic diseases. With the BIG4 Health App,
                                    healthcare providers can help patients to effectively managing the BIG4: high blood pressure, diabetes, high cholesterol,
                                    and obesity through exercise, healthy eating, stress management, medication adherence and much more. Get a more engaged
                                    workforce with our latest technology.</p>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="business-sec-img">
                                <img src={require("../images/update/health-app-img.png").default} alt="img" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>


            <section className="lifestyle-med-sec">
                <div className="container">
                    <div className="health-app-head">
                        <h2>Lifestyle Medicine for what matters most- our health!</h2>
                        <p>The BIG4 Health App is built on the foundation of Lifestyle Medicine, emphasizing the importance of nutrition, physical activity, stress
                            management, social interaction, and regenerative sleep. By using the app, patients can also track their BIG4 numbers, receive
                            recommendations for lifestyle changes, and set reminders for medication and doctor visits. The users get engaged in healthy lifestyle
                            choices that prevent and manage chronic conditions effectively.</p>
                    </div>
                </div>
            </section>

            <section className="true-patient-sec">
                <div className="container">
                    <div className="health-app-head text-right">
                        <h2>A true patient health companion!</h2>
                        <p>Imagine being able to take a proactive approach to managing your health, reducing the need for costly hospitalizations and ongoing
                            medical care. With the BIG4 Health App, you can do just that. By incorporating Lifestyle Medicine principles, the app provides
                            users with the tools they need to take control of their health and well-being.</p>
                    </div>
                </div>
            </section>

            <section className="smarter-future-sec">
                <div className="container">
                    <div className="health-app-head text-right">
                        <h2>Explore a smarter future for healthcare – together.</h2>
                        <p>Transform the way you care for patients with complex chronic conditions using the BIG4 Health App. This cutting-edge medical app
                            empowers patients with natural tools for holistic care. It enables providers to work smarter and more efficiently in hospitals,
                            private practices, or remotely. Elevate the quality of care for high blood pressure, diabetes, high cholesterol, and obesity.
                            Try the BIG4 Health App today and experience comprehensive care immediately.</p>
                    </div>
                </div>
            </section>

            <section className="empower-future-sec">
                <div className="container">
                    <div className="health-app-head">
                        <h2>Empower your patients for <br />smarter health.</h2>
                        <p>Join the millions of users already benefiting from this comprehensive <br />and personalized approach to managing chronic conditions. Take <br />
                            control of your health today with the BIG4 Health App.</p>
                    </div>
                </div>
            </section>


          

            {queryheader !== "false" &&
             allQuizes.length > 0 &&
                <section ref={quizSectionRef} id="Quizlist" className="quiz_slider py-4">
                    <div className="container">
                        <div className="row">
                            <aside className="col-md-12 col-sm-12 mb-2">
                                <div className="">
                                    <h2 className="">BIG4 QUIZ</h2>
                                    { console.log(isPlayedFirstTime)}
                                    {isPlayedFirstTime ?
                                        <p className="text-center">Do you want to play more? We will get back to you soon with new challenges.</p> :
                                        <p>Play the BIG4 Quiz in {city ? city : 'your location'}! By answering the questions in a given time and get a chance to win the rewards.</p>
                                    }
                                </div>

                            </aside>
                            <aside className="col-md-12">
                                <Swiper className="mySwiper"
                                    navigation
                                    spaceBetween={20}
                                    slidesPerView={6}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        576: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        1366: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                    }}
                                >
                                    {allQuizes.length > 0 && allQuizes.map((quiz, i) => {
                                        return (
                                            <SwiperSlide>
                                                <div className="quiz_section_box inner-slide">
                                                    <span className="qstn"> {quiz.questions.length} Questions</span>
                                                    <h6>{quiz.name}</h6>
                                                    <div className="quiz_footer mt-3">
                                                        <button className="ans bg-transparent" onClick={() => handleModalShowHide(quiz._id, quiz.quiz_done_status)}>{quiz.quiz_done_status === 'COM' ? "Check your answers" : "Answer this quiz"}</button>
                                                        
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </aside>
                        </div>
                    </div>
                </section>
}
            
            
{queryheader !== "false" &&  <section className="download-app">
                <div className="container">
                    <Row className="align-items-center">
                        <Col md={5}>
                            <div className="business-sec-img">
                                <img src={require("../images/update/download-app.png").default} alt="img" />
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className="health-app-text">
                                <h1>Download the BIG4 Health App</h1>
                                <p>Join the millions of users already benefiting from this comprehensive and personalized approach to managing chronic conditions. Take
                                    control of your health today with the BIG4 Health App.</p>
                                <div className="play-apple-store">
                                    <a href="https://apps.apple.com/us/app/big4health/id1517973481" target="_blank"><img src={require("../images/update/apple-store.png").default} alt="img" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.big4healthapp" target="_blank"><img src={require("../images/update/play-store.png").default} alt="img" /></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>}
            {queryheader !== "false" &&  <section className="get-started-form bg_section signup_section redesign-profile">
                <div className="container">
                    <h3> We’re here with the experts and personalized guidance to help you navigate the current healthcare landscape.</h3>
                    <div className="get-form-wrap">
                        <h2>Get Started</h2>

                        <aside className="col-lg-12 pt-4">
                            <div className="Signup_box text-center w-100">
                                <Form className="">
                                    <div className="row frist_step  ">
                                        <aside className="col-md-4 mb-3 text-left">
                                            <label>
                                                Full Name<span className="mandatory"></span>
                                            </label>
                                            <div className="input_row">
                                                <input
                                                    type="firstName"
                                                    name="name"
                                                    maxLength={50}
                                                    autoComplete="off"
                                                    placeholder="Enter Full Name"
                                                    className="input103 w-100"
                                                    value={userDetail?.name}
                                                    onChange={(e) => inputChange(e)}

                                                />
                                            </div>
                                        </aside>
                                        <aside className="col-md-4 mb-3 text-left">
                                            <label>
                                                Email<span className="mandatory"></span>
                                            </label>
                                            <div className="input_row">
                                                <input
                                                    type="Email"
                                                    name="email"
                                                    autoComplete="off"
                                                    placeholder="Enter Email"
                                                    className="input103 w-100"
                                                    value={userDetail?.email}
                                                    onChange={(e) => inputChange(e)}

                                                />
                                            </div>
                                        </aside>
                                        <aside className="col-md-4 mb-3 text-left">
                                            <label>
                                                Phone Number<span className="mandatory"></span>
                                            </label>
                                            <div className="input_row">
                                                <input
                                                    type="PhoneNumber"
                                                    autoComplete="off"
                                                    name="PhoneNumber"
                                                    placeholder="Enter Phone Number"
                                                    className="input103 w-100"
                                                    value={userDetail?.PhoneNumber}
                                                    onChange={(e) => inputChange(e)}

                                                />
                                            </div>
                                        </aside>
                                        <aside className="col-md-4 mb-3 text-left">
                                            <label>
                                                Company Name<span className="mandatory"></span>
                                            </label>
                                            <div className="input_row">
                                                <input
                                                    type="Text"
                                                    name="companyName"
                                                    autoComplete="off"
                                                    maxLength={50}
                                                    placeholder="Enter Company Name"
                                                    className="input103 w-100"
                                                    value={userDetail?.companyName}
                                                    onChange={(e) => inputChange(e)}


                                                />
                                            </div>
                                        </aside>
                                       

                                        <aside className="col-md-4 mb-3 text-left">
                                            <label>Country </label>
                                            <div className="input_row input103">


                                                <select
                                                    className="w-100 select-main"
                                                    name="country"
                                                    value={userDetail?.country}
                                                    onChange={(e) => inputChange(e)}
                                                >
                                                    <option value={""}>Select Country</option>
                                                    {Country.getAllCountries().map((c) => {
                                                        return (
                                                            <option value={c.name}>{c.name}</option>
                                                        )
                                                    })}

                                                </select>
                                            </div>
                                        </aside>
                                        
                                        <aside className="col-md-4 mb-3 text-left">
                      <label>Service</label>
                      <>
                        <a variant="primary" onClick={handleShow} className='d-block servies-text'>
                          {/* {userDetail?.service?.length > 0 ? "+ Update Services" : "+ Add Services"} */}
                          {userDetail?.service?.filter(item => item)?.length > 0 ? "+ Update Services" : "+ Add Services"}
                         
                        </a>
                        <div className='services-preview'>
                        {userDetail?.service && <span>{showFullContent ? userDetail?.service?.filter(item => item)?.join(" , ") : userDetail?.service?.filter(item => item)?.join(" , ")?.slice(0, 30)}</span>}
                          {userDetail?.service && (userDetail?.service?.join(" ")?.length > 30) ? (
                            <p onClick={toggleContent}>{showFullContent ? 'View less' : 'View more'}</p>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    </aside>


                                        <aside className="col-md-12 mb-3 text-left">
                                            <div className="input_row">
                                                <label>Message</label>
                                                <textarea
                                                    className=" w-100 select-main"
                                                    placeholder="Enter Message"
                                                    autoComplete="off"
                                                    name="message"
                                                    maxLength={500}
                                                    value={userDetail?.message}
                                                    onChange={(e) => inputChange(e)}
                                                ></textarea>
                                            </div>
                                        </aside>
                                    </div>
                                </Form>
                            </div>
                            <div className="next_btn text-center">
                                <Button
                                    variant="secondary"
                                    type="button"
                                    className="btn w-25"
                                    onClick={handleSumit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </aside>

                    </div>
                </div>
            </section>}

            {queryheader !== "false" &&  <Footer />}
          
            <Modal show={showHide} className="quizmodal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="border-0 mb-1 pb-1 align-items-center">
                    <Modal.Title className="text-center"><h5>Are you ready to start the BIG4 Quiz?</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row pb-2">
                            <div className="col-md-12">
                                <p>You have 2 minutes to complete the quiz.</p>
                                <p>There is no way to comeback if you quit the quiz in the middle.</p>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <aside className="col-sm-6">
                                <span className="not_btn">
                                    <button className="btn" onClick={() => handleModalShowHide('', 'NEW')}>NOT NOW</button>
                                </span>
                            </aside>
                            <aside className="col-sm-6">
                                <span className="start_btn">
                                    <a className="btn" onClick={() => { user_id ?
                                      startQuiz() :
                                        Swal.fire("Warning", "To perform this action Login is required.", "warning").then((res) => {
                                            window.location.href = "/signin";
                                            let redirectUri = window.location.pathname + window.location.search;
                                            localStorage.setItem("redirectUri", redirectUri);
                                        })
                                    } }
                                     >START QUIZ</a>
                                </span>
                            </aside>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>

            <Modal show={show} 
            onHide={(e) =>{handleClose(); handleClearText();}}
             className='services-box'>
        <Modal.Header closeButton>
          <Modal.Title>Get Started, the services available </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='services-box'>
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Prevention and Reversal Programs
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox1">
                          <Form.Check type="checkbox" label="Diabetes" checked={userDetail.service.includes('Diabetes')} value="Diabetes" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>

                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox2">
                          <Form.Check type="checkbox" label="High Blood Pressure" checked={userDetail.service.includes('High Blood Pressure')} value="High Blood Pressure" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox3">
                          <Form.Check type="checkbox" label="High Cholesterol" checked={userDetail.service.includes('High Cholesterol')} value="High Cholesterol" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox4">
                          <Form.Check type="checkbox" label="Obesity Prevention/Reversal Programs" checked={userDetail.service.includes('Obesity Prevention/Reversal Programs')} value="Obesity Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox5">
                          <Form.Check type="checkbox" label="Chronic Disease Prevention/Reversal Programs" checked={userDetail.service.includes('Chronic Disease Prevention/Reversal Programs')} value="Chronic Disease Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Health and Wellness Applications
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox6">
                          <Form.Check type="checkbox" label="Medical & Health Apps" checked={userDetail.service.includes('Medical & Health Apps')} value="Medical & Health Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox7">
                          <Form.Check type="checkbox" label="Wellness Apps" checked={userDetail.service.includes('Wellness Apps')} value="Wellness Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Workplace Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox8">
                          <Form.Check type="checkbox" label="Corporate Wellness Programs" checked={userDetail.service.includes('Corporate Wellness Programs')} value="Corporate Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox9">
                          <Form.Check type="checkbox" label="Health and Wellness Consulting" checked={userDetail.service.includes('Health and Wellness Consulting')} value="Health and Wellness Consulting" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Community Health Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox10">
                          <Form.Check type="checkbox" label="Population Health Improvement Programs" checked={userDetail.service.includes('Population Health Improvement Programs')} value="Population Health Improvement Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox11">
                          <Form.Check type="checkbox" label="Behavioral Health Strategic Management" checked={userDetail.service.includes('Behavioral Health Strategic Management')} value="Behavioral Health Strategic Management" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox12">
                          <Form.Check type="checkbox" label="Certified Community Behavioral Health Clinics (CCBHCs)" checked={userDetail.service.includes('Certified Community Behavioral Health Clinics (CCBHCs)')} value="Certified Community Behavioral Health Clinics (CCBHCs)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Nutrition and Health Products:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox13">
                          <Form.Check type="checkbox" label="Nutrition" checked={userDetail.service.includes('Nutrition')} value="Nutrition" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox14">
                          <Form.Check type="checkbox" label="Cancer-Fighting Meals" checked={userDetail.service.includes('Cancer-Fighting Meals')} value="Cancer-Fighting Meals" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox15">
                          <Form.Check type="checkbox" label="Meal Replacement Solutions" checked={userDetail.service.includes('Meal Replacement Solutions')} value="Meal Replacement Solutions" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox16">
                          <Form.Check type="checkbox" label="Health Supplements" checked={userDetail.service.includes('Health Supplements')} value="Health Supplements" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox17">
                          <Form.Check type="checkbox" label="Health Wellness Programs" checked={userDetail.service.includes('Health Wellness Programs')} value="Health Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox18">
                          <Form.Check type="checkbox" label="Employee Engagement " checked={userDetail.service.includes('Employee Engagement')} value="Employee Engagement" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    {/* Health Advocacy and Information: */}
                    Healthcare Speaker & Health Information
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox19">
                          <Form.Check type="checkbox" label="Healthcare Speakers and Keynote Speakers" checked={userDetail.service.includes('Healthcare Speakers and Keynote Speakers')} value="Healthcare Speakers and Keynote Speakers" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox20">
                          <Form.Check type="checkbox" label="Public Health Policies for Government Agencies " checked={userDetail.service.includes('Public Health Policies for Government Agencies')} value="Public Health Policies for Government Agencies" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox21">
                          <Form.Check type="checkbox" label="Health Data Analytics (Making complex data understandable and useful)" checked={userDetail.service.includes('Health Data Analytics (Making complex data understandable and useful)')} value="Health Data Analytics (Making complex data understandable and useful)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="7">
                    Collaboration Opportunities
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox22">
                          <Form.Check type="checkbox" label="Become a Partner" checked={userDetail.service.includes('Become a Partner')} value="Become a Partner" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="8">
                  Build Custom Medical Apps
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox23">
                          <Form.Check type="checkbox" label="Custom Medical Apps" checked={userDetail.service.includes('Custom Medical Apps')} value="Custom Medical Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
                <label className='mt-3'><b>Other</b></label>
               <textarea
                className="form-control"
                placeholder="Please Specify"
                name="service"
                maxLength={500}
                value={serviceOther? serviceOther : ""}
                onChange={handleInputChange}
              ></textarea>
            </Accordion>
          </div>
          {userDetail?.service?.length > 0 &&<a
            type="button"
            className="join-button"
            onClick={handleSubmit2}

          >
            Submit
          </a>}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
        </>
    );
};
export default Health_app