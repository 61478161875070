import { useEffect, useRef, useState } from "react"
import { userService } from "../services"
import { config,appName } from "../config/config"
import { Button, Modal } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react';
import Swal from 'sweetalert2';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ShareOnSocialMedia from "../Components/common/ShareOnSocialMedia"
export const DATE_OPTIONS = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }

function WorldUserdata(props) {
  const handleCloseSuccess = () => {
    setShowSuccess(false)
  }

 

const [copy, setCopy] = useState(true);
// let title =`Hey! Checkout this health webinar on ${appName}`;
let title = `Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link.`
const [share_url, setshare_url] = useState(null);
const [show, setShow] = useState(false);
const [showSuccess, setShowSuccess] = useState(false);
    const[userDetail, setUserDetail]=useState("")
useEffect(() =>{
  let data=window.location.href
  setshare_url(data)
},[])

useEffect(() => {
  if (copy) {
    const interval = setInterval(() => {
      setCopy(false);
    }, 1000);
    return () => clearInterval(interval);
  }
}, [copy]);

    const play =useRef("")
    let history =useHistory()
    const[userid, setUserId] =useState(localStorage?.getItem("user_id") ? localStorage?.getItem("user_id") :"" )
        const { slug } = useParams()
        useEffect(() =>{
            if(props){
                getWebnairDetails(props?.data)
            }
        },[props])
      
        function getWebnairDetails(slug){
          userService.webnair_detail(slug).then((res) =>{
              setUserDetail(res?.data?.data)
          })
          .catch((Error) =>{
          })
      }
      let pathname=window.location.pathname?.split("/")[1];

      // const shareOnWhatsApp = async (url, title) => {
      //   if (navigator.share) {
      //     try {
      //       await navigator.share({
      //         title,
      //         url,
      //       });
      //     } catch (error) {
      //       console.error('Error sharing on WhatsApp:', error);
      //       // Fallback to default behavior
      //       window.location.href = `https://wa.me/?text=${encodeURIComponent(`${title}: ${url}`)}`;
      //     }
      //   } else {
      //     // Fallback to default behavior
      //     window.location.href = `https://wa.me/?text=${encodeURIComponent(`${title}: ${url}`)}`;
      //   }
      // };

      const shareOnWhatsApp = async (url, title) => {
        const os = getOperatingSystem();
        console.log("Operating System:", os);
      
        if (os === "Windows" || os === "Linux" || os === "MacOS") {
          if (await isWhatsAppInstalled()) {
            console.log("WhatsApp app is available. Sharing on WhatsApp app.");
            try {
              await navigator.share({
                title,
                url,
              });
              console.log("Shared successfully!");
            } catch (error) {
              console.error('Error sharing on WhatsApp:', error);
              fallbackToWhatsAppWeb(url, title);
            }
          } else {
            console.log("WhatsApp app is not available. Redirecting to WhatsApp Web.");
            fallbackToWhatsAppWeb(url, title);
          }
        } else {
          console.log("Unsupported operating system.");
        }
      };
      
      const getOperatingSystem = () => {
        const platform = navigator.platform.toLowerCase();
        if (platform.includes("win")) {
          return "Windows";
        } else if (platform.includes("linux")) {
          return "Linux";
        } else if (platform.includes("mac")) {
          return "MacOS";
        } else {
          return "Unknown";
        }
      };
      
      const isWhatsAppInstalled = async () => {
        return new Promise((resolve) => {
          const isWhatsApp = /WhatsApp/.test(navigator.userAgent);
          resolve(isWhatsApp);
        });
      };
      
      const fallbackToWhatsAppWeb = (url, title) => {
        console.log("WhatsApp app is not available. Opening WhatsApp link.");
        window.open(`https://wa.me/?text=${encodeURIComponent(`${title}: ${url}`)}`, '_blank');
      };

      function shareOnWhatsapp(url, text) {
        const encodedUrl = encodeURIComponent(url);
        const encodedText = encodeURIComponent(text);
        const navUrl = `https://api.whatsapp.com/send?text=${encodedText}%0A${encodedUrl}`;
        window.open(navUrl, '_blank');
      }



    return (
        <>
             <div className="big-med-details top-spacing-area">
                <div className="container">
                    <div className="back-btn-sec mb-3 d-flex align-item-center justify-content-between flex-wrap">
                         <Button onClick={(e) =>{
                            if(pathname =="profilenew"){
                                history.push("/profilenew?activeMenu=big4healthworld")
                            }
                            else if(pathname =="profile"){
                              history.push(`/profile/${userDetail?.userDetail?.userSlug}`)
                            }
                        }}
                        className="btn back-btn"><i className="fa  fa-angle-left"></i>Back to Listing</Button>
                        <button className="join-button mt-0" onClick={(e) =>setShowSuccess(true)} >
                        <i class="fa fa-share-alt mr-1" aria-hidden="true"></i> Share
                                            </button>
                    </div>
                    <div className="details-nor-sec">
                      <div className="row">
                        <aside className="col-md-12">
                          <div className="big-med-details-text">
                              <div className="big-med-text big-med-text-main mb-4">
                                  <div className="new-txt-wraps">
                                  <h4>{userDetail?.title}</h4>
                                 
                                  <p className="mb-2"><img src={require("../images/update/date.svg").default} alt="img" className="mr-2"/> Start date&time: {userDetail && new Intl.DateTimeFormat('en', DATE_OPTIONS).format(new Date(userDetail?.date))}</p>
                                  {userDetail?.webinar_endTime && <p className="mb-2"><img src={require("../images/update/date.svg").default} alt="img" className="mr-2"/> End date&time: {userDetail && new Intl.DateTimeFormat('en', DATE_OPTIONS).format(new Date(userDetail?.webinar_endTime))}</p>}

                                  <p className="blue-text mb-2"><img src={require("../images/update/language.svg").default} alt="img" className="mr-2" />{userDetail?.language}</p>
                                  <p className="mb-2">Category:<b> {userDetail?.big_categories?.name}</b></p>
                                  <p className="mb-2">Webinar link:<b>  { userDetail && userDetail?.timePassed == "past" ?
                       "Webinar date has passed" : 
                       userDetail?.timePassed =="live" ?
                        <a class="join-button" href="javascript:;"
                              onClick={(e) => {
                                if(localStorage?.getItem("user_id")) {
                                   window.open(userDetail?.webinar_url,"_blank");
                                }else{
                                  Swal.fire('warning',"To perform this action login is required","warning").then((e)=>{
                                    window.location.href = '/signin';
                                  })
                                }
                            }}>Join Now</a>
                        :
                        "Coming Soon" }</b></p>
                                  </div>
                                  <div className="big-txt-btn"></div>
                                  
                              </div>
                              <div className="big-med-icon-text d-flex">
                                  <div className="big-med-thumbnail" onClick={((e) =>{
                                    window.location.href=`/profile/${userDetail?.userDetail?.userSlug}`;
                                  })}>
                                      <img src={userDetail && userDetail?.userDetail?.profilePicture && userDetail?.userDetail?.profilePicture !=="undefined" ? userDetail?.userDetail?.profilePicture : require("../images/update/dummy-profile-img.jpg").default} alt="img" />
                                  </div>
                                  <div className="big-med-text mr-4">
                                      <div className="big-txt-in">
                                      <p className="channel-name">{userDetail?.userDetail?.name}</p>
                                      <p><span>{userDetail?.userDetail?.professionalType}</span></p>
                                      </div>
                                      <div className="btn-subs">
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="description mt-3 mb-5">
                            <p>{userDetail?.description}</p>
                          </div>
                        </aside>
                       
                      </div>
                    </div>
                   
                </div>
                
                
            </div>
            <Modal show={showSuccess} onHide={handleCloseSuccess} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="email-verfication text-center">
            <h3 className="mt-4 mb-3 black-text">Share Details </h3>
            {/* <p className="black-text">
              <span>Would you like to share it with your friends/communities?</span>
            </p> */}
             <p className="black-text">
              <span> Share this new post with 10 friends to expand your network!</span>
            </p>
            <div className="copy-profile d-flex mb-4">
              {copy ? (
                <>
                  <span style={{ fontSize: "12px", color: "#ff7750" }}>
                    Link Copied
                  </span>
                  <br></br>
                </>
              ) : (
                <>
                  <span style={{ fontSize: "10px" }}></span>
                  <br></br>
                </>
              )}
              <input
                type="text"
                value={share_url}
                disabled={true}
                style={{ fontSize: "12px" }}
              />
              <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
                <i
                  className="fa fa-clipboard Demo__some-network__share-button"
                  title="Copy to clipboard"
                  aria-hidden="true"
                  style={{ fontSize: "19px", cursor: "pointer" }}
                ></i>
              </CopyToClipboard>
            </div>
            <div className="congrats-social-icons mb-2">
              {/* <FacebookShareButton
                url={share_url}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <EmailShareButton
                url={share_url}
                subject={title}
                body="Lincode Card: "
                separator=" "
                className="Demo__some-network__share-button"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
              <TwitterShareButton
                                url={share_url}
                                title={title}
                                className="Demo__some-network__share-button share-detail-icons"
                            >
                                <i class="fa-brands fa-x-twitter"></i>
                            </TwitterShareButton>
                            <button className="btn btn-sm whtsp-btn share-whtsapp-icon share-detail-icons-whatsapp" data-toggle="tooltip" title="Whatsapp" onClick={() =>shareOnWhatsapp(share_url, title)}>
                                <i className="fa fa-whatsapp" />
                            </button> */}
                             <ShareOnSocialMedia url={window.location.href}  text="Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link." />
            </div>
          </div>
        </Modal.Body>
      </Modal>
        </>
    )

}
export default WorldUserdata;