import { useState, useEffect, useRef } from 'react';
import Header from './common/Header'
import Footer from './common/Footer'
import { Button, Form, Modal } from 'react-bootstrap';
import { userService } from '../services/user.services';
import Swal from "sweetalert2";
import Loader from './common/Loader';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { GetVideoThumbnails, appName, appUrl } from '../config/config';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
} from "react-share";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { uploadFileToS3 } from '../config/AwsConfig';
import ReactLanguageSelect from "react-languages-select";
import 'react-languages-select/css/react-languages-select.css';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import ShareOnSocialMedia from './common/ShareOnSocialMedia';



const Create_post_form = () => {
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const { transcript, resetTranscript } = useSpeechRecognition();
  const bottomRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [share_url, setshare_url] = useState(null);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showIng, setShowIng] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  let title = `Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link.`
  const [spokenText, setSpokenText] = useState(''); // New state to store spoken text


  const handleCloseIng = () => setShowIng(false);

  const handleClosed = () => setShow(false);

  const handleShowIng = () => setShowIng(true);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setUserDetails({ ...userDetail, mediaType: "" });
    setShow(true)
  }

  const handleCloseSuccess = () => {
    if (localStorage.getItem("userType") === "Professional") {
      window.location.href = "/big-med";
      setShowSuccess(false)
    }
    else if (localStorage.getItem("userType") === "Client") {
      window.location.href = "/big-me";
      setShowSuccess(false)
    }
  }
  const handleShowSuccess = () => setShowSuccess(true);

  const [categoryList, setcategoryList] = useState()
  const [userDetail, setUserDetails] = useState({ mediaType: "", "Addmedia_pic": [], "security_label": "", "title": "", "category": "", "newClient": "true", "upload_thumbnail": "", "medical_icon": "", "description": "", "termandCondition": false, "healthcare": "false", "HospitalName": "" })

  function inputChange(e) {
    let { name, value, checked } = e.target;
    console.log("name, value", name, value, checked)
    setUserDetails({ ...userDetail, [name]: name === "termandCondition" ? checked : value });
  }
  const [copy, setCopy] = useState(true);

  useEffect(() => {
    if (copy) {
      const interval = setInterval(() => {
        setCopy(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [copy]);


  useEffect(() => {
    setUserDetails({ ...userDetail, description: transcript });
    if (isListening) {
      const textInputEl = inputRef.current;
      textInputEl.scrollTop = textInputEl?.scrollHeight;
    }
  }, [transcript])
  const browserLanguage = navigator.language || navigator.userLanguage;
  const handleListing = () => {
    setIsListening(true);
    microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      continuous: true,
      language: selectedLanguage,
      interimResults: true,
    });
    setTimeout(stopHandle, 120000)
  };

  const stopHandle = () => {
    setIsListening(false);
    microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();
    setUserDetails({ ...userDetail, description: (document.getElementById("input_focused").value) });

  };
  const handleReset = () => {
    setUserDetails({ ...userDetail, description: "" })
    stopHandle();
    resetTranscript();
  };

  const getSpeechLanguage = () => {

    return localStorage.getItem("userLaguage") || 'en-US'; // Default to English if not found
  };

  async function handleProfilePicData(e) {
    const imageFile = thumbnailImageUrl ? thumbnailImageUrl : e.target.files[0];
    let profileImageUrl = '';

    if (imageFile) {
      let profileImageData = await uploadFileToS3(imageFile, 'post');
      profileImageUrl = profileImageData?.Location;

      if (imageFile.type.startsWith('image/')) {
        setUserDetails({ ...userDetail, upload_thumbnail: profileImageUrl });
      } else {
        setUserDetails({ ...userDetail, upload_thumbnail: '' });
        Swal.fire('Warning', 'Please select a valid image file.', 'warning');
      }
    }

    const filename = imageFile.name;
    e.target.parentNode.querySelector('.upload-text').innerText = filename;
  }



  const selectedMediaRef = useRef(null);
  const thumbnailRef = useRef()
  let thumbnailImageUrl = "";
  let profileImageUrl = "";
  const handleMediaChange = async (mediaType, e) => {
    async function setVaric(fileData) {
      await setUserDetails({
        ...userDetail,
        Addmedia_pic: fileData,
      });
    }

    let fileList = [...e.target.files];
    let fileData = [];

    if (mediaType == "image" && fileList.length > 3) {
      Swal.fire("Warning", "you can upload maximum 3 images.", "warning");
      return;
    } else if (mediaType != "image" && fileList.length > 1) {
      Swal.fire("Warning", "you can upload maximum 1 file.", "warning");
      return;
    }

    for (let index = 0; index < fileList.length; index++) {
      const selectedFile = fileList[index];

      if (selectedFile) {
        selectedMediaRef.current.value = null;
        fileData = [...fileData, selectedFile];

        switch (mediaType) {
          case 'image':
            if (selectedFile.type.startsWith('image/')) {
              setShow(false);
            } else {
              Swal.fire("Warning", "Invalid Image Type.", "warning");
            }
            break;
          case 'video':
            if (selectedFile.type.startsWith('video/')) {
              thumbnailImageUrl = await GetVideoThumbnails({ file: selectedFile });
              if (thumbnailImageUrl) {
                let profileImageData = await uploadFileToS3(thumbnailImageUrl, "post");
                thumbnailRef.current = profileImageData?.Location;
                setUserDetails({ ...userDetail, upload_thumbnail: profileImageData?.Location })
              }
              setShow(false);
            } else {
              Swal.fire("Warning", "Invalid Video Type.", "warning");
            }
            break;

          case 'pdf':
            if (selectedFile.type === 'application/pdf') {
              setShow(false);
            } else {
              Swal.fire("Warning", "Invalid PDF Type.", "warning");
            }
            break;

          case 'voice':
            if (selectedFile.type.startsWith('audio/')) {
              setShow(false);
            } else {
              Swal.fire("Warning", "Invalid Voice Type.", "warning");
            }
            break;

          default:
            break;
        }
      }
      if (index === fileList.length - 1) {
        await setVaric(fileData);
      }
    }
  };


  const handleMediaClick = (mediaType) => {
    setUserDetails({ ...userDetail, mediaType: mediaType });
    selectedMediaRef.current.click();
  }

  useEffect(() => {
    getCotagery()
  }, [])

  function getCotagery() {
    userService.get_category().then((res) => {
      setcategoryList([...res?.data?.data])
    })
      .catch((Error) => {
      })
  }

  const handleChange = (event) => {
    const selectedCategory = event.target.value;

    setUserDetails({ ...userDetail, category: selectedCategory });
  };


  async function createNewPost() {
    let profileImageUrl = "";
    try {
      setIsLoading(true);
      var formData = new FormData();
      let profileImageUrl = "";
      await Promise.all(userDetail.Addmedia_pic.map(async (item) => {
        if (item) {
          console.log("uploaded file type here: ", item.type);
          let profileImageData = await uploadFileToS3(item, "post", item.type);
          profileImageUrl = profileImageData?.Location;
        }
        formData.append("media", profileImageUrl);
      }));
      formData.append("media_type", userDetail.mediaType);
      formData.append("title", userDetail.title);
      formData.append("severity_level", userDetail.security_label);
      formData.append("category", userDetail.category);
      formData.append("thumbnail_icon", userDetail.upload_thumbnail ? userDetail.upload_thumbnail : thumbnailRef.current);
      formData.append("description", userDetail.description);
      formData.append("termandCondition", userDetail.termandCondition);
      if (localStorage.getItem("userType") == "Professional") {
        formData.append("looking_provider", userDetail.newClient);
        formData.append("HospitalName", userDetail?.HospitalName);
      }
      if (localStorage.getItem("userType") == "Client") {
        formData.append("healthcare_provider", userDetail.healthcare);
      }
      const res = await userService.create_new_post(formData);
      if (res?.data?.status === 200) {
        setIsLoading(false);
        if (res?.data?.addPosts?.userType == "Professional") {
          setShowSuccess(true);
          setshare_url(appUrl + `big-med-details?id=${res?.data?.addPosts?._id}`);
        }
        if (res?.data?.addPosts?.userType == "Client") {
          setShowSuccess(true);
          setshare_url(appUrl + `big-me-details?id=${res?.data?.addPosts?._id}`);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  }


  function handleSubmit(e) {
    e.preventDefault()
    if (!userDetail?.Addmedia_pic || userDetail?.Addmedia_pic == "") {
      Swal.fire("Warning", "Please upload media picture", "warning");
      return
    }
    else
      if (!userDetail?.title || userDetail?.title?.trim() === "") {
        Swal.fire("Warning", "Please enter title.", "warning");
        return
      }
      else
        if (!userDetail?.security_label || userDetail?.security_label === "") {
          Swal.fire("Warning", "Please select severity level.", "warning");
          return
        }
        else
          if (!userDetail?.category || userDetail?.category === "") {
            Swal.fire("Warning", "Please select Choose Category.", "warning");
            return
          }
          else
            if ((!userDetail?.newClient || userDetail?.newClient === "") && localStorage.getItem("userType") == "Professional") {
              Swal.fire("Warning", "Please select Taking New Clients.", "warning");
              return
            }
            else if ((!userDetail?.healthcare || userDetail?.healthcare === "") && localStorage.getItem("userType") === "Client") {
              Swal.fire("Warning", "Please select healthcare provider.", "warning");
            }
            else
              if (!userDetail?.description || userDetail?.description?.trim() === "") {
                Swal.fire("Warning", "Please enter description.", "warning");
                return
              }
              else
                if (!userDetail?.termandCondition) {
                  Swal.fire("Warning", "Please select Accept Our Privacy Policy and Term and Conditions.", "warning");
                  return
                }
                else {
                  createNewPost()
                }
  }

  const handleRemoveImage = (indexToRemove) => {
    setUserDetails(prevUserDetails => {
      const updatedAddMediaPic = prevUserDetails.Addmedia_pic.filter(
        (image, index) => index !== indexToRemove
      );
      if (updatedAddMediaPic.length > 0) {
        return {
          ...prevUserDetails,
          Addmedia_pic: updatedAddMediaPic
        };

      }
      else {
        return {
          ...prevUserDetails,
          Addmedia_pic: updatedAddMediaPic,
          mediaType: null,
        };
      }

    });
  };

  const handleRemoveMedia = (index) => {
    const updatedMedia = [...userDetail.Addmedia_pic];
    updatedMedia.splice(index, 1);

    setUserDetails({
      ...userDetail,
      Addmedia_pic: updatedMedia,
      mediaType: null,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
    }
  };


  const shareOnWhatsApp = async (url, title) => {
    const os = getOperatingSystem();
    console.log("Operating System:", os);

    if (os === "Windows" || os === "Linux" || os === "MacOS") {
      if (await isWhatsAppInstalled()) {
        console.log("WhatsApp app is available. Sharing on WhatsApp app.");
        try {
          await navigator.share({
            title,
            url,
          });
          console.log("Shared successfully!");
        } catch (error) {
          console.error('Error sharing on WhatsApp:', error);
          fallbackToWhatsAppWeb(url, title);
        }
      } else {
        console.log("WhatsApp app is not available. Redirecting to WhatsApp Web.");
        fallbackToWhatsAppWeb(url, title);
      }
    } else {
      console.log("Unsupported operating system.");
    }
  };

  const getOperatingSystem = () => {
    const platform = navigator.platform.toLowerCase();
    if (platform.includes("win")) {
      return "Windows";
    } else if (platform.includes("linux")) {
      return "Linux";
    } else if (platform.includes("mac")) {
      return "MacOS";
    } else {
      return "Unknown";
    }
  };

  const isWhatsAppInstalled = async () => {
    return new Promise((resolve) => {
      const isWhatsApp = /WhatsApp/.test(navigator.userAgent);
      resolve(isWhatsApp);
    });
  };

  const fallbackToWhatsAppWeb = (url, title) => {
    console.log("WhatsApp app is not available. Opening WhatsApp link.");
    window.open(`https://wa.me/?text=${encodeURIComponent(`${title}: ${url}`)}`, '_blank');
  };

  function shareOnWhatsapp(url, text) {
    const encodedUrl = encodeURIComponent(url);
    const encodedText = encodeURIComponent(text);
    const navUrl = `https://api.whatsapp.com/send?text=${encodedText}%0A${encodedUrl}`;
    window.open(navUrl, '_blank');
  }

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <section className="bg_section signup_section redesign-profile">
        <div className="container">
          <div className="back-btn-sec mb-3">
            <Button className="btn back-btn"
              onClick={(e) => { localStorage.getItem("userType") == "Professional" ? window.location.href = "/big-med" : window.location.href = "/big-me" }}
            ><i className="fa fa-angle-left mr-2"></i>Back to Listing</Button>
          </div>
          <div className="row">
            <aside className="col-md-12">
              <div className="Signup_box">
                <h4 className="mb-4">CREATE NEW POST</h4>
                <div className="row border-1">
                  <aside className="col-lg-12 pt-4">
                    <div className="Signup_box text-center w-100">
                      <div data-toggle="tooltip" data-placement="top" title="Add Media"
                        className={!userDetail?.mediaType ? "camera_box mt-3" : (userDetail?.mediaType == "pdf" || userDetail?.mediaType == "voice") ? "camera_box mt-3 camera-box-uploaded pdf-audio" : ((userDetail?.mediaType == "image" || userDetail?.mediaType == "video") && userDetail?.Addmedia_pic?.length > 1) ? "camera_box mt-3 camera-box-uploaded camera-img-box" : !userDetail?.Addmedia_pic ? "camera_box mt-3" : "camera_box mt-3 camera-box-uploaded"}
                        style={{ position: "relative" }}
                      >
                        {userDetail?.Addmedia_pic && userDetail?.Addmedia_pic.length > 0 ?
                          <>
                            {
                              userDetail?.mediaType == "image" && userDetail?.Addmedia_pic?.length > 0 ? (
                                userDetail?.Addmedia_pic?.map((image, index) => (
                                  <div key={index} className="image-container">
                                    <img key={index} data-toggle="tooltip" data-placement="top" title={userDetail?.Addmedia_pic[0]?.name} src={URL.createObjectURL(image)} alt="Image Preview"
                                    />
                                    <span
                                      className="cancel-icon"
                                      onClick={() => handleRemoveImage(index)}
                                    >
                                      &#10006;
                                    </span>
                                  </div>
                                )))

                                : userDetail?.mediaType == "video" ? (
                                  <div className="media-container">
                                    <video data-toggle="tooltip" data-placement="top" title={userDetail?.Addmedia_pic[0]?.name} controls playsInline width="300">
                                      <source src={URL.createObjectURL(userDetail?.Addmedia_pic[0])} type="video/mp4" />
                                      <source src={URL.createObjectURL(userDetail?.Addmedia_pic[0])} type="video/ogg" />
                                    </video>
                                    <span className="cancel-icon" onClick={() => handleRemoveMedia(userDetail?.Addmedia_pic[0])}>
                                      &#10006;
                                    </span>
                                  </div>)

                                  :
                                  userDetail?.mediaType == "voice" ?
                                    <>
                                      <img
                                        data-toggle="tooltip" data-placement="top" title={userDetail?.Addmedia_pic[0]?.name}
                                        src={
                                          require("../images/update/audio-upload.png")
                                            .default
                                        }
                                        alt="img"
                                      />
                                      <span className="cancel-icon" onClick={() => handleRemoveMedia(userDetail?.Addmedia_pic[0])}>
                                        &#10006;
                                      </span>
                                    </>
                                    :
                                    userDetail?.mediaType == "pdf" ?
                                      <>
                                        <iframe
                                          data-toggle="tooltip" data-placement="top" title={userDetail?.Addmedia_pic[0]?.name}
                                          src={URL.createObjectURL(userDetail?.Addmedia_pic[0])}
                                          width="100%"
                                          height="500"

                                        ></iframe>
                                        <img
                                          src={
                                            require("../images/update/pdf-upload.png")
                                              .default
                                          }
                                          alt="img"
                                        />
                                        <span className="cancel-icon" onClick={() => handleRemoveMedia(userDetail?.Addmedia_pic[0])}>
                                          &#10006;
                                        </span>

                                      </> :
                                      <></>}
                          </> : null}

                        {!userDetail?.mediaType && <a>
                          <i className="fa fa-upload"></i>
                        </a>}

                      </div>


                      <a href="#" className="upload-add-media" onClick={handleShow}>
                        Add Media
                      </a>
                      <p className='add-media-des'><i>Upload video(up to 10 minutes)-MP3 recording-image-lab results or research papers.</i></p>
                    </div>
                  </aside>
                  <aside className="col-lg-12 pt-4">
                    <div className="Signup_box text-center w-100">
                      <div className="">
                        <div className="row frist_step  contact-form-area ">
                          <aside className="col-md-6 mb-4 text-left">
                            <label>
                              Post Title<span className="blue-asterisk">*</span><span className="mandatory"></span>
                            </label>
                            <div className="input_row">
                              <input
                                type="title"
                                value={userDetail?.title}
                                onKeyDown={(e) => {
                                  if (!e.target.value && e.code === "Space") {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={50}
                                onChange={(e) => inputChange(e)}
                                name="title"
                                placeholder="Enter Title"
                                className="input103 w-100"
                              />
                            </div>
                          </aside>
                          <aside className="col-md-6 mb-4 text-left">
                            <div className="input_row">
                              <label>Case Severity Level<span className="blue-asterisk">*</span> </label>
                              <select
                                className="input103 w-100 select-main"
                                name='security_label'
                                onKeyDown={(e) => {
                                  if (!e.target.value && e.code === "Space") {
                                    e.preventDefault();
                                  }
                                }}
                                value={userDetail?.security_label}
                                onChange={(e) => inputChange(e)}
                              >
                                <option value="" disabled >Severity Level</option>
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                                <option value="Low">Low</option>
                              </select>
                            </div>
                          </aside>


                          <aside className="col-md-6 mb-4 text-left">
                            <div className="input_row">
                              <label>Category<span className="blue-asterisk">*</span></label>
                              <select
                                className="input103 w-100 select-main"
                                name="Choose Category"
                                placeholder="Choose Category"
                                value={userDetail?.category} // Use "category" instead of "value"
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Choose Category
                                </option>
                                {categoryList?.map((category) => (
                                  <option key={category?._id} value={category?._id}>
                                    {category?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </aside>




                          {localStorage && localStorage?.getItem("userType") == "Professional" &&
                            <aside className="col-md-6  mb-3 text-left">
                              <div className="input_row checkbox-area">
                                <label>
                                  Taking New Clients?<span className="blue-asterisk">*</span>
                                </label>
                                <Form className="mt-3">
                                  {["radio"].map((type) => (
                                    <div
                                      key={`inline-${type}`}
                                      className="mb-3"
                                    >
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        name="newClient"
                                        type={type}
                                        id={`inline-${type}-1`}
                                        checked={(userDetail.newClient == "true")}
                                        value={!(userDetail.newClient == false)}
                                        onChange={(e) => inputChange(e)}
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        name="newClient"
                                        type={type}
                                        id={`inline-${type}-2`}
                                        checked={(userDetail.newClient == "false")}
                                        value={(userDetail.newClient == false)}
                                        onChange={(e) => inputChange(e)}
                                      />
                                    </div>
                                  ))}
                                </Form>
                              </div>
                            </aside>

                          }

                          {localStorage && localStorage?.getItem("userType") == "Client" && <aside className="col-md-6 mb-4 text-left">
                            <div className="input_row checkbox-area">
                              <label>
                                Looking for a healthcare provider?<span className="blue-asterisk">*</span>
                              </label>
                              <Form className="mt-2">
                                {["radio"].map((type) => (
                                  <div
                                    key={`inline-${type}`}
                                    className="mb-4 mobile-radio-space"
                                  >
                                    <Form.Check
                                      inline
                                      label="Yes"
                                      name="healthcare"
                                      type={type}
                                      id={`inline-${type}-1`}
                                      checked={(userDetail.healthcare == "true")}
                                      value={!(userDetail.healthcare == false)}
                                      onChange={(e) => inputChange(e)}
                                    />
                                    <Form.Check
                                      inline
                                      label="No"
                                      name="healthcare"
                                      type={type}
                                      id={`inline-${type}-2`}
                                      checked={(userDetail.healthcare == "false")}
                                      value={(userDetail.healthcare == false)}
                                      onChange={(e) => inputChange(e)}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </aside>}


                          {userDetail?.mediaType != "image" && <aside className="col-md-6 mb-4 text-left">
                            <label>
                              Upload Thumbnail <span className="optional">(Optional)</span>
                            </label>
                            <div className="upload-document create-document">
                              <div className="input_row">
                                <input
                                  type="file"
                                  accept="image/"
                                  name="upload_thumbnail"
                                  onChange={(e) => handleProfilePicData(e)}
                                  placeholder={userDetail?.upload_thumbnail == "" ? "Upload Thumbnail" : userDetail?.upload_thumbnail?.name}
                                  className="input103 w-100"
                                />
                                {userDetail?.upload_thumbnail == "" ? <span className="upload-text">Upload Thumbnail</span> : <span className="upload-text">{userDetail?.upload_thumbnail?.name}</span>}
                                <img
                                  src={
                                    require("../images/update/upload.svg")
                                      .default
                                  }
                                  alt="img"
                                />
                              </div>
                              {userDetail?.upload_thumbnail !== "" && <div className="thumbnail-image">
                                <a href='#' onClick={handleShowIng} >View thumbnail</a>
                              </div>}
                            </div>
                          </aside>}

                          {localStorage && localStorage?.getItem("userType") == "Professional" && <aside className="col-md-6 mb-4 text-left">
                            <label>
                              Hospital or Clinic Name<span className="optional">(Optional)</span>
                            </label>
                            <div className="input_row">
                              <input
                                type="HospitalName"
                                value={userDetail?.HospitalName}
                                maxLength={50}
                                onKeyDown={(e) => {
                                  if (!e.target.value && e.code === "Space") {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => inputChange(e)}
                                name="HospitalName"
                                placeholder="Enter Hospital or Clinic Name"
                                className="input103 w-100"
                              />
                            </div>
                          </aside>}



                          <aside className="col-md-12 mb-4 text-left notranslate msg-placement" >
                            <label>Description<span className="blue-asterisk">*</span></label>
                            <div className="input_row">
                              <textarea
                                type=""
                                rows="4"
                                id="input_focused"
                                maxLength={1000}
                                name="description"
                                ref={inputRef}
                                onKeyDown={(e) => {
                                  if (!e.target.value && e.code === "Space") {
                                    e.preventDefault();
                                  }
                                }}
                                value={userDetail?.description}
                                onChange={(e) => inputChange(e)}
                                placeholder="Enter Description"
                                className="textarea103 w-100 create-post-description"

                              />


                              <div className="send-record-btn">


                                <div className="microphone-section-area">


                                  <>

                                    <div
                                      className="microphone-icon-container submit"
                                      ref={microphoneRef}
                                      onClick={(e) => {
                                        if (isListening) {
                                          stopHandle()
                                          return
                                        }
                                        else {
                                          handleListing()
                                        }
                                      }}
                                    >
                                      {!isListening ?
                                        <i class={"fa fa-microphone microphone-icon"} aria-hidden="true"></i>
                                        :
                                        <i class="fa fa-stop-circle-o" aria-hidden="true"></i>
                                      }
                                    </div>
                                    <div className="microphone-status">

                                      <i class="fa-light fa-waveform-lines"></i>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>

                            <div className="language-select-bottom">
                              <ReactLanguageSelect
                                names={["both"]}
                                searchable={true}
                                onSelect={(languageCode) => {
                                  console.log("Selected Language Code:", languageCode);
                                  setSelectedLanguage(languageCode);
                                }}
                              />

                            </div>



                          </aside>
                          <label className="d-flex pl-3 radio-text-mob">
                            <input
                              type="checkbox"
                              name="termandCondition"
                              className="remem mr-2"
                              defaultValue={userDetail.termandCondition ? true : false}
                              checked={userDetail.termandCondition ? true : false}
                              onChange={(e) => inputChange(e)}
                            />{" "}<span className='text-left'>
                              I Accept{" "}
                              <a
                                className="mx-1"
                                href="/privacy-policy"
                                target="_blank"
                              >
                                {" "}
                                Privacy Policy
                              </a>{" "}
                              and{" "}
                              <a className="ml-1" href="/terms" target="_blank">
                                {" "}
                                Terms & Conditions.
                              </a>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="next_btn text-center mt-4">
                      <Button
                        variant="secondary"
                        type="button"
                        className="btn w-25"
                        onClick={handleSubmit}

                      >
                        Create Post
                      </Button>
                    </div>
                  </aside>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>

      <Modal show={showIng} onHide={handleCloseIng} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className='pt-0' >
          <div className="email-verfication text-center">
            <h3 className="mb-2">View Thumbnail</h3>
            {userDetail.upload_thumbnail && <div className='thumnail-image'>
              <img src={userDetail.upload_thumbnail} alt="img" />
            </div>}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="email-verfication text-center">

            <h3 className="mt-2">Choose Media</h3>
            <div className="four-boxes-media d-flex justify-content-center">
              <input type="file" ref={selectedMediaRef} multiple style={{ display: "none" }} onChange={(e) => handleMediaChange(userDetail.mediaType, e)} />
              <div className="add-media-box" onClick={() => handleMediaClick("image")}>
                <img src={require("../images/update/add-media-image.png").default} alt="img" />

                <h6>Image</h6>
                <p><i>(1256X654 px)</i></p>
              </div>
              <div className="add-media-box media-box-orange" onClick={() => handleMediaClick("video")}>
                <img src={require("../images/update/add-media-video.png").default} alt="img" />

                <h6>Video</h6>
                <p><i>(up to 10 minutes)</i></p>
              </div>
            </div>
            <div className="four-boxes-media d-flex justify-content-center">
              <div className="add-media-box media-box-yellow" onClick={() => handleMediaClick("pdf")}>
                <img src={require("../images/update/add-media-pdf.png").default} alt="img" />
                <h6>PDF</h6>
              </div>
              <div className="add-media-box media-box-green" onClick={() => handleMediaClick("voice")}>
                <img src={require("../images/update/add-media-voice.png").default} alt="img" />
                <h6>Voice</h6>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal show={showSuccess} onHide={handleCloseSuccess} centered >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="email-verfication text-center">
            <img src={require("../images/update/congrats.png").default} alt="img" />
            <h3 className="mt-4 mb-3 black-text">Your post has been created successfully.</h3>
            <p className="black-text">
              <span>Share your new post with 10 friends to expand your network!</span>
            </p>
            <div className="copy-profile d-flex mb-4">
              {copy ? (
                <>
                  <span style={{ fontSize: "12px", color: "#ff7750" }}>
                    Link Copied
                  </span>
                  <br></br>
                </>
              ) : (
                <>
                  <span style={{ fontSize: "10px" }}></span>
                  <br></br>
                </>
              )}
              <input
                type="text"
                value={share_url}
                disabled={true}
                style={{ fontSize: "12px" }}
              />
              <CopyToClipboard text={share_url} onCopy={() => setCopy(true)}>
                <i
                  className="fa fa-clipboard Demo__some-network__share-button"
                  title="Copy to clipboard"
                  aria-hidden="true"
                  style={{ fontSize: "19px", cursor: "pointer" }}
                ></i>
              </CopyToClipboard>
            </div>
            <div className="congrats-social-icons mb-2">

              <ShareOnSocialMedia url={share_url} text="Join me on this awesome FREE health social network! It's super exciting and packed with helpful resources. Let's stay motivated together! Interested? Click on this link." />
            </div>
          </div>
        </Modal.Body>
      </Modal>




      <Footer />
    </>
  );
};

export default Create_post_form;