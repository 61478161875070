import React from 'react'
import { useEffect, useState } from "react";
import Header from './common/Header'
import Footer from './common/Footer'
import { Button, Col, Form, Row, Card, Accordion, Modal } from "react-bootstrap";
import { userService } from '../services';
import { toast } from 'react-toastify';
import moment from 'moment'
import { Country, State, City, getAllCountries } from "country-state-city";
import Swal from 'sweetalert2';
import Loader from './common/Loader';
import ReactStars from "react-rating-stars-component";

const OrderDetails = () => {
  const [showFullContent, setShowFullContent] = useState(false);
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };
  const [showTrack, setShowTrack] = useState(false);
  const handleCloseTrack = () => setShowTrack(false);
  const handleShowTrack = () => setShowTrack(true);
  const [serviceOther, setserviceOther] = useState("")
  const [userDetail, setuserDetails] = useState({ email: "", name: "", PhoneNumber: "", companyName: "", country: "", service: [""], message: "" })
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [lgShow, setLgShow] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [productId, setProductId] = useState('');
  const [productstatus, setProductStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [card, setCard] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [userId, setUserId] = useState('');
  const [cartCount, setCartCount] = useState('');
  const [favCount, setFavCount] = useState('');
  const [cancelReason, setCancelReason] = useState('');
  const [tax, setTax] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [returnReason, setReturnReason] = useState('');
  const [returnModal, setReturnModal] = useState(false);
  const [lgShowPast, setLgShowPast] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  useEffect(() => {
    let user_id = localStorage.getItem('user_id');
    if (user_id) setUserId(user_id);
    getDetails()
  }, []);

  function getDetails() {
    setIsLoading(true);
    userService.getOrderDetails(id).then((res) => {
      if (res.status == 200) {
        setIsLoading(false);
        setOrderDetail(res.data.data)
        setProductStatus(true)
        let isCard = res.data.data.card ? res.data.data.card : 'Wallet';
        setCard(isCard);
        // setTax(res.data.data.tax);
        setShipping(res.data.data.shipping_charge);
      }
    })
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
    setserviceOther(value)
  };

  const cancelOrder = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let params = { cancel_reason: cancelReason }
    console.log("params=======",params)
    // if (!cancelReason) {
    //   toast.error('Please enter cancellation reason', {
    //     toastId: 'success_draft_1',
    //   })
    // } else {
    //   userService.cancelOrder(urlParams.get('id'), params).then(res => {
    //     setLgShow(false)
    //     setCancelReason("")
    //     window.location.reload()
    //     if (res?.data?.status === 400) {
    //       toast.error(res?.data?.message)
    //     }
    //   })
    //     .catch((error) => {
    //     })
    // }
  }

  function submitReason() {
    if (!returnReason) {
      toast.error("Please enter a return reason.", {
        toastId: 'success_draft_1',
      })
    } else if (!isAccepted) {
      toast.error("Please accept the terms and conditions.", {
        toastId: 'success_draft_1',
      })
    } else {
      let data = {
        returnReason: returnReason
      }
      userService.submitReturnReason(data, orderDetail._id).then(res => {
        setReturnModal(false)
        getDetails()
        if (res.status == 200) {
        }
      })
    }
  }

  const cancelSub = () => {
    window.location.href = '/profile'
  }

  function submitRating() {
    if (!rating) {
      toast.error("Please select rating.", {
        toastId: 'success_draft_1',
      })
    } else {
      setIsLoading(true)
      let data = {
        order: id,
        product: orderDetail.product._id,
        user: localStorage.getItem('user_id'),
        rating: rating,
        review: review
      }
      userService.submitRating(data).then(res => {
        setLgShowPast(false)
        getDetails()
        if (res.status == 200) {
        }
      })
    }
  }

  function getPrice() {
    return (orderDetail?.price) * (orderDetail?.qty)
  }

  function getTax() {
    console.log("parseFloat((orderDetail?.price / 100) * orderDetail.tax).toFixed(2)", parseFloat((orderDetail?.price / 100) * orderDetail.tax).toFixed(2))
    return parseFloat((orderDetail?.price / 100) * orderDetail.tax).toFixed(2)
  }

  function getSubDiscount() {
    return orderDetail.discountValue?.toFixed(2);
  }

  function getGrandTotalPrice() {
    let gPrice = getPrice();
    gPrice -= getSubDiscount()
    gPrice = gPrice + parseFloat(getTax())
    gPrice += orderDetail.shipping_charge;
    return gPrice ? parseFloat(gPrice).toFixed(2) : "";
  }

  function getDiscountedPrice(item) {
    return (item.price - (item.discount / 100) * item.price).toFixed(2)
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedService;

    if (checked) {
      updatedService = [...userDetail.service, value];
    } else {
      updatedService = userDetail.service.filter((item) => item !== value);
    }
    setuserDetails((prevState) => ({
      ...prevState,
      service: updatedService,
    }));
  };
  const [service, setservice] = useState(false)

  const handleSubmit2 = () => {
    let temp = [...userDetail.service];
    temp[0] = serviceOther;
    setuserDetails((prevState) => ({
      ...prevState,
      service: temp,
    }));
    setservice(true)
    setShow(false)

    // Perform any additional actions you need with the 'service' value.
  };

  function inputChange(e) {
    let { name, value } = e.target;
    setuserDetails({ ...userDetail, [name]: value });
  }

  function handleSumit(e) {
    e.preventDefault();
    const EMAILREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    var pattern = new RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i);
    if (!userDetail.name || userDetail?.name.trimStart() == "") {
      Swal.fire("Warning", "Name field can’t be empty.", "warning")
      return
    }
    else if (!userDetail.email || userDetail?.email.trimStart() == "") {
      Swal.fire("Warning", "E-mail field can’t be empty.", "warning")
      return
    }
    else if (!EMAILREGEX.test(userDetail.email)) {
      Swal.fire("Warning", 'Entered E-mail address is not valid.', "warning")
      return
    }
    else if (!userDetail.PhoneNumber || userDetail?.PhoneNumber.trimStart() == "") {
      Swal.fire("Warning", 'Phone Number field can’t be empty.', "warning")
      return
    }
    else if (!userDetail.companyName || userDetail?.companyName.trimStart() == "") {
      Swal.fire("Warning", 'Company Name field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.country || userDetail?.country.trimStart() == "") {
      Swal.fire("Warning", 'country field can’t be empty.', "warning")
      return
    }
    // else if (!userDetail?.service) {
    //   Swal.fire("Warning", 'service field can’t be empty.', "warning")
    //   return
    // }
    else if (!userDetail?.service?.filter(item => item)?.length) {
      Swal.fire("Warning", 'service field can’t be empty.', "warning")
      return
    }
    else if (!userDetail?.message || userDetail?.message.trimStart() == "") {
      Swal.fire("Warning", 'message field can’t be empty.', "warning")
      return
    }
    else {
      contactus()
    }
  }
  let preview;

  function contactus() {
    const params = {
      name: userDetail.name,
      email: userDetail?.email,
      companyName: userDetail?.companyName,
      country: userDetail?.country,
      PhoneNumber: userDetail?.PhoneNumber,
      service: userDetail?.service,
      message: userDetail?.message,
      page_type: "business"
    }
    userService.contactUs(params).then((res) => {
      if (res?.data?.status === 200) {
        // console.log("res----", res);
        Swal.fire("Success", res?.data?.message, "success").then((res) => {
          setuserDetails({
            email: "",
            name: "",
            PhoneNumber: "",
            companyName: "",
            country: "",
            service: [""],
            message: ""
          });
          // window.location.reload()
          // window.scrollTo({ top: 0, left: 0, behavior: "instant" })
        })
        setuserDetails({
          email: "",
          name: "",
          PhoneNumber: "",
          companyName: "",
          country: "",
          service: [""],
          message: ""
        });
      }
    })
      .catch((error) => {
        // console.log("error", error)
      })
  }

  // const handleClearText = () => {
  //   if (service == false) {
  //     setuserDetails({
  //       service: [""],
  //     });
  //   }
  // }
  const handleClearText = () => {
    if(service == false){
      setuserDetails({ ...userDetail,
        service: [""],
      });
    }
  }

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <section className='order-details-section'>
        <div className='order-top'>
          <marquee>
            {/* <h5>Your order is yet to be confirmed.</h5> */}
            <div className="details_popup text-center">

              {orderDetail?.status == "ACT" || orderDetail?.status == "ONWAY" ?
                <marquee><p>{orderDetail.status == 'ONWAY' ? "Your order is on the way." : "Your order is yet to be confirmed."}</p></marquee>
                :
                orderDetail?.status == "CAN" ?
                  <marquee><p><img src={require('../images/cancellation1.svg').default} alt="" className="mr-1" /> You had  cancelled your order on "{moment(orderDetail?.cancelled_on).format("MMM DD, YYYY") + " at " + moment(orderDetail?.cancelled_on).format("hh:mm A")}”. Your payment will be back within 5 working days in your bank account. If you have any concern then feel
                    free to contact us.</p></marquee>
                  :
                  orderDetail?.status == "COM" && orderDetail?.returnRequested == true ?
                    orderDetail?.refunded == true ? <marquee><p>The order is returned and refunded.</p></marquee>
                      : <marquee><p>The Return is processed and order has been delivered to you on "{moment(orderDetail?.completedDate).format("MMM DD, YYYY") + " at " + moment(orderDetail?.completedDate).format("hh:mm A")}".</p></marquee>
                    : <marquee><p>Your order has been delivered to you on "{moment(orderDetail?.completedDate).format("MMM DD, YYYY") + " at " + moment(orderDetail?.completedDate).format("hh:mm A")}".</p></marquee>}
            </div>
          </marquee>
        </div>
      </section>

      <section className="shopping-cart-sec burden-disease">
        <div className="container">
          <div className="back-btn-sec mb-3 d-flex justify-content-between">
            <Button className="btn back-btn"
              onClick={(e) => {
                window.location.href = `/profilenew?activeMenu=${"orders"}`
              }}
            ><i className="fa fa-angle-left"></i>Back to Orders</Button>
          </div>
          <div className="banner-text d-flex justify-content-between align-items-center">
            <h1 className="text-center">Product Details</h1>
            {orderDetail.status == 'ACT' ? <a href='#' className='shopping-btn delete-btn' onClick={() => setLgShow(true)}>Cancel Order</a> : null}
            {orderDetail?.status == "ONWAY" ? <button className='track-order' variant="unset" onClick={handleShowTrack}>Track Order</button> : null}


          </div>
          <div className="row">
            <aside className="col-lg-8">
              <div className="shopping-cart-wrap">
                <div className="row align-items-center">
                  <aside className="col-md-4">
                    <div className="product-box">
                      <img
                        src={
                          require("../images/update/Ing-product.png").default
                        }
                        alt="img"
                      />
                    </div>
                  </aside>
                  {orderDetail ? <aside className="col-md-8">
                    <div className="product-box">
                      <p><b>{moment(orderDetail?.createdAt).format("MMM DD, YYYY") + " at " + moment(orderDetail?.createdAt).format("hh:mm A")}</b></p>
                      <h5>{orderDetail?.product?.name}</h5>
                      <div className="ingredient-buy-btn order-detail-price">
                        <p>{orderDetail?.product?.discount > 0 && <span>${orderDetail?.product?.price}</span>}
                          {productstatus == true && getDiscountedPrice(orderDetail?.product)}
                        </p>
                      </div>
                      <p><b>Qty: </b>{orderDetail?.qty}</p>
                      <p><b>Size: {orderDetail?.lineitem?.size ? orderDetail?.lineitem?.size : ""}</b></p>
                      <div className="color_row shopping-clr">
                        <div className="color_row">
                          <span
                            style={{ backgroundColor: orderDetail?.lineitem?.color }}
                          >
                            { }
                          </span>
                        </div>
                      </div>
                      <h4 className="blue-text mt-3"><b>${orderDetail?.grandtotal > 0 ? orderDetail?.grandtotal.toFixed(2) : getGrandTotalPrice()}</b></h4>
                    </div>
                  </aside> : ""}

                </div>
              </div>
            </aside>

            <aside className="col-lg-4">
              <div className="total-count h-100">
                <div className="card-body" style={{ background: "#e0f8ff" }}>
                  <dl>
                    <h6 className="mt-0">{orderDetail?.product?.name}</h6>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Total price:</dt>
                    <dd className="text-right ml-3">${getPrice() ? getPrice()?.toFixed(2) : ""}</dd>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Total Service Tax:</dt>
                  </dl>
                  <dl className="">
                    {orderDetail?.tax && orderDetail?.tax?.map((tax, index) => {
                      return (
                        <div className="dlist-align" key={index}>
                          <dt>Tax Type({tax?.tax_rate_details?.tax_type}):</dt>
                          <dd className="text-right text-success ml-3">
                            ${(tax?.amount / 100).toFixed(2)}
                          </dd>
                        </div>
                      );
                    })}
                  </dl>

                  <dl className="dlist-align">
                    <dt>Shipping:</dt>
                    <dd className="text-right ml-3">${orderDetail.shipping_charge ? parseFloat(orderDetail.shipping_charge).toFixed(2) : ""}</dd>
                  </dl>
                  <hr />
                  <dl className="dlist-align mb-0">
                    <dt style={{ fontSize: "20px", color: "black" }}>Total:</dt>
                    <dd className="text-right ml-3" style={{ fontSize: "20px" }}><strong>${orderDetail?.grandtotal > 0 ? orderDetail?.grandtotal.toFixed(2) : getGrandTotalPrice()}</strong></dd>
                  </dl>
                </div>
              </div>
            </aside>

            <aside className="col-lg-6 mt-3">
              <div className="total-count h-100">
                <h6 className='mb-3'>PAYMENT VIA</h6>
                <div className="cardbox bg-light hight-unset">
                  <div className="cardbox_header mb-3">
                    <h6 className="text-dark">{card?.card_holder_name}</h6>
                    < img src={require(`../../src/images/update/visa.png`).default} />
                  </div>
                  <a className="car_btn">
                    <h5>{card !== 'Wallet' ? 'XXXX XXXX XXXX' : 'Wallet'}<span>{card.last4}</span></h5>

                  </a>
                </div>
              </div>
            </aside>

            <aside className="col-lg-6 mt-3">
              <div className="total-count h-100">
                <h6 className='mb-3'>ADDRESS:</h6>
                <h5 className="my-1">{orderDetail?.address?.name}</h5>
                <h6 className="mt-2 mb-1">{orderDetail?.address?.phone}</h6>
                <p className="m-0">{orderDetail?.address?.street1}, {orderDetail?.address?.city}, {orderDetail?.address?.state} & {orderDetail?.address?.zip_code}</p>
              </div>
            </aside>


          </div>
        </div>
      </section>

      <section className="revies_section mt-4">
        <div className="container">
          <div className="row">
            {orderDetail?.rating !== null && <div className="col-md-12">
              <h5 style={{ fontSize: "17px" }}>YOUR RATING</h5>
            </div>}

            <div className="col-md-12">


              {orderDetail?.rating ? <div>
                <div className="review_head mt-2">
                  <h5 className="my-2">
                    {Array.from(Array(orderDetail?.rating.rating)).map(t => {
                      return <i class="fa fa-star" aria-hidden="true" />
                    })}
                    <span></span></h5>
                  <h6><i className="fa fa-clock-o"></i> {moment(orderDetail?.rating.createdAt).fromNow()}</h6>
                </div>
                <p>{orderDetail?.rating.review}</p>

              </div> : <div className="review_btn text-center">
                <button class="btn" onClick={() => setLgShowPast(true)}>Give Rating & Reviews</button>
              </div>
              }

            </div>

            <div className="container">
              {((moment().isBefore(moment(orderDetail?.createdAt).add(7, 'days')) && orderDetail?.returnRequested == false)) ?
                <div className="col-md-12 text-center mt-10" style={{ marginTop: '20px' }} onClick={() => setReturnModal(true)}>
                  {orderDetail?.status == "COM" ? <h5 class="btn product-return" style={{ fontSize: "17px" }}>Want to return?</h5> : ""}
                </div>
                :
                orderDetail?.returnRequested == true ? <div className="col-md-12 text-center mt-10" style={{ marginTop: '20px' }}>
                  <h5 style={{ fontSize: "17px" }}>
                    {orderDetail?.refunded == false ? 'The Return is processed. Please send the parcel on the mentioned address: 416 6th Street SW Willmar Minnesota 56201.' : 'The order is returned and refunded.'}
                  </h5>
                </div> : null
              }
            </div>


          </div>
        </div>
      </section>



      <section className="get-started-form bg_section signup_section redesign-profile">
        <div className="container">
          <h3> We’re here with the experts and personalized guidance to help you navigate the current healthcare landscape.</h3>
          <div className="get-form-wrap">
            <h2>Get Started</h2>

            <aside className="col-lg-12 pt-4">
              <div className="Signup_box text-center w-100">
                <Form className="">
                  <div className="row frist_step  contact-form-area ">
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Full Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="firstName"
                          name="name"
                          maxLength={50}
                          autoComplete="off"
                          placeholder="Enter Full Name"
                          className="input103 w-100"
                          value={userDetail?.name}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Email<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Email"
                          name="email"
                          autoComplete="off"
                          placeholder="Enter Email"
                          className="input103 w-100"
                          value={userDetail?.email}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Phone Number<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="PhoneNumber"
                          name="PhoneNumber"
                          autoComplete="off"
                          placeholder="Enter Phone Number"
                          className="input103 w-100"
                          value={userDetail?.PhoneNumber}
                          onChange={(e) => inputChange(e)}

                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>
                        Company Name<span className="mandatory"></span>
                      </label>
                      <div className="input_row">
                        <input
                          type="Text"
                          name="companyName"
                          maxLength={50}
                          autoComplete="off"
                          placeholder="Enter Company Name"
                          className="input103 w-100"
                          value={userDetail?.companyName}
                          onChange={(e) => inputChange(e)}


                        />
                      </div>
                    </aside>
                    <aside className="col-md-4 mb-3 text-left">
                      <label>Country </label>
                      <div className="input_row input103">


                        <select
                          className="w-100 select-main"
                          name="country"
                          value={userDetail?.country}
                          onChange={(e) => inputChange(e)}
                        >
                          <option value={""}>Select Country</option>
                          {Country.getAllCountries().map((c) => {
                            return (
                              <option value={c.name}>{c.name}</option>
                            )
                          })}

                        </select>
                      </div>
                    </aside>

                    <aside className="col-md-4 mb-3 text-left">
                      <label>Service</label>
                      <>
                        <a variant="primary" onClick={handleShow} className='d-block servies-text'>
                          {/* {userDetail?.service?.length > 0 ? "+ Update Services" : "+ Add Services"} */}
                          {userDetail?.service?.filter(item => item)?.length > 0 ? "+ Update Services" : "+ Add Services"}

                        </a>

                        <div className='services-preview'>
                          {userDetail?.service && <span>{showFullContent ? userDetail?.service?.filter(item => item)?.join(" , ") : userDetail?.service?.filter(item => item)?.join(" , ")?.slice(0, 30)}</span>}
                          {userDetail?.service && (userDetail?.service?.join(" ")?.length > 30) ? (
                            <p onClick={toggleContent}>{showFullContent ? 'View less' : 'View more'}</p>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    </aside>


                    <aside className="col-md-12 mb-3 text-left">
                      <div className="input_row">
                        <label>Message</label>
                        <textarea
                          className=" w-100 select-main"
                          placeholder="Enter Message"
                          autoComplete="off"
                          name="message"
                          maxLength={500}
                          value={userDetail?.message}
                          onChange={(e) => inputChange(e)}
                        ></textarea>
                      </div>
                    </aside>
                  </div>
                </Form>
              </div>
              <div className="next_btn text-center">
                <Button
                  variant="secondary"
                  type="button"
                  className="btn w-25"
                  onClick={handleSumit}
                >
                  Submit
                </Button>
              </div>
            </aside>

          </div>
        </div>
      </section>
      <Modal show={show}
        onHide={(e) => { handleClose(); handleClearText(); }}
        className='services-box'>
        <Modal.Header closeButton>
          <Modal.Title>Get Started, the services available </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='services-box'>
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Prevention and Reversal Programs
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox1">
                          <Form.Check type="checkbox" label="Diabetes" checked={userDetail.service.includes('Diabetes')} value="Diabetes" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>

                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox2">
                          <Form.Check type="checkbox" label="High Blood Pressure" checked={userDetail.service.includes('High Blood Pressure')} value="High Blood Pressure" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox3">
                          <Form.Check type="checkbox" label="High Cholesterol" checked={userDetail.service.includes('High Cholesterol')} value="High Cholesterol" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox4">
                          <Form.Check type="checkbox" label="Obesity Prevention/Reversal Programs" checked={userDetail.service.includes('Obesity Prevention/Reversal Programs')} value="Obesity Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox5">
                          <Form.Check type="checkbox" label="Chronic Disease Prevention/Reversal Programs" checked={userDetail.service.includes('Chronic Disease Prevention/Reversal Programs')} value="Chronic Disease Prevention/Reversal Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Health and Wellness Applications
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox6">
                          <Form.Check type="checkbox" label="Medical & Health Apps" checked={userDetail.service.includes('Medical & Health Apps')} value="Medical & Health Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox7">
                          <Form.Check type="checkbox" label="Wellness Apps" checked={userDetail.service.includes('Wellness Apps')} value="Wellness Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Workplace Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox8">
                          <Form.Check type="checkbox" label="Corporate Wellness Programs" checked={userDetail.service.includes('Corporate Wellness Programs')} value="Corporate Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox9">
                          <Form.Check type="checkbox" label="Health and Wellness Consulting" checked={userDetail.service.includes('Health and Wellness Consulting')} value="Health and Wellness Consulting" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Community Health Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox10">
                          <Form.Check type="checkbox" label="Population Health Improvement Programs" checked={userDetail.service.includes('Population Health Improvement Programs')} value="Population Health Improvement Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox11">
                          <Form.Check type="checkbox" label="Behavioral Health Strategic Management" checked={userDetail.service.includes('Behavioral Health Strategic Management')} value="Behavioral Health Strategic Management" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox12">
                          <Form.Check type="checkbox" label="Certified Community Behavioral Health Clinics (CCBHCs)" checked={userDetail.service.includes('Certified Community Behavioral Health Clinics (CCBHCs)')} value="Certified Community Behavioral Health Clinics (CCBHCs)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Nutrition and Health Products:
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox13">
                          <Form.Check type="checkbox" label="Nutrition" checked={userDetail.service.includes('Nutrition')} value="Nutrition" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox14">
                          <Form.Check type="checkbox" label="Cancer-Fighting Meals" checked={userDetail.service.includes('Cancer-Fighting Meals')} value="Cancer-Fighting Meals" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox15">
                          <Form.Check type="checkbox" label="Meal Replacement Solutions" checked={userDetail.service.includes('Meal Replacement Solutions')} value="Meal Replacement Solutions" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox16">
                          <Form.Check type="checkbox" label="Health Supplements" checked={userDetail.service.includes('Health Supplements')} value="Health Supplements" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    Wellness Initiatives
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox17">
                          <Form.Check type="checkbox" label="Health Wellness Programs" checked={userDetail.service.includes('Health Wellness Programs')} value="Health Wellness Programs" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox18">
                          <Form.Check type="checkbox" label="Employee Engagement " checked={userDetail.service.includes('Employee Engagement')} value="Employee Engagement" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    {/* Health Advocacy and Information: */}
                    Healthcare Speaker & Health Information
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox19">
                          <Form.Check type="checkbox" label="Healthcare Speakers and Keynote Speakers" checked={userDetail.service.includes('Healthcare Speakers and Keynote Speakers')} value="Healthcare Speakers and Keynote Speakers" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox20">
                          <Form.Check type="checkbox" label="Public Health Policies for Government Agencies " checked={userDetail.service.includes('Public Health Policies for Government Agencies')} value="Public Health Policies for Government Agencies" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox21">
                          <Form.Check type="checkbox" label="Health Data Analytics (Making complex data understandable and useful)" checked={userDetail.service.includes('Health Data Analytics (Making complex data understandable and useful)')} value="Health Data Analytics (Making complex data understandable and useful)" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="7">
                    Collaboration Opportunities
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox22">
                          <Form.Check type="checkbox" label="Become a Partner" checked={userDetail.service.includes('Become a Partner')} value="Become a Partner" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="8">
                    Build Custom Medical Apps
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    <div className='row'>
                      <aside className='col-md-6'>
                        <Form.Group controlId="formBasicCheckbox23">
                          <Form.Check type="checkbox" label="Custom Medical Apps" checked={userDetail.service.includes('Custom Medical Apps')} value="Custom Medical Apps" onChange={handleCheckboxChange} />
                        </Form.Group>
                      </aside>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <label className='mt-3'><b>Other</b></label>
              <textarea
                className="form-control"
                placeholder="Please specify"
                name="service"
                maxLength={500}
                value={serviceOther ? serviceOther : ""}
                onChange={handleInputChange}
              ></textarea>
            </Accordion>
          </div>
          {userDetail?.service?.length > 0 && <a
            type="button"
            className="join-button"
            onClick={handleSubmit2}

          >
            Submit
          </a>}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      {/* contact us section start */}

      <Modal show={lgShow}
        centered className='quiz-pop appointment-box cancel-order-popup'>
        <Modal.Header
        >
          <Modal.Title><h4><b>Cancel Order</b></h4></Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className='appointment-box text-center'>
            <h5 className='mb-4'>Are you sure you want to Cancel this <b>"{orderDetail?.product?.name}"</b>?</h5>
            <div className="Signup_box text-center contact-main-box">
              <Form className="text-left mt-3 cancel-order-area">
                <label className='text-left'>
                  GIVE A REASON<span className="mandatory"></span>
                </label>
                <textarea
                  onChange={e => setCancelReason(e.target.value)}
                  value={cancelReason}
                  placeholder="Enter Reason">
                </textarea>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none m-auto">
          <Button className='btn notnowbtn' onClick={() => setLgShow(false)}>NO</Button>
          <Button className='btn quiznow-btn' onClick={() => cancelOrder()}>SUBMIT</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={lgShowPast}
        onHide={() => setLgShowPast(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className='rating-area'
        centered
      >
        <Modal.Header className="border-0 pb-0">
          <Modal.Title id="example-modal-sizes-title-lg">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 pt-0 mt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center p-0">
                <div className="cancelorder_modal">
                  <h5 style={{ fontSize: "24px", color: "black" }}>Give Rating & Review</h5>
                  <div class="text-center">
                    <ReactStars
                      count={5}
                      size={49}
                      value={rating}
                      onChange={(rating) => setRating(rating)}
                      activeColor="#ffd700"
                      classNames="ratingrow"
                    />
                  </div>
                  <label className="mt-3">REVIEW MESSAGE</label>
                  <textarea onChange={(review) => setReview(review.target.value)} onKeyDown={(e) => {
                    if (!e.target.value && e.code === "Space") {
                      e.preventDefault();
                    }
                  }} placeholder="Enter Review Message"></textarea>
                  <div className='rating-area-btns'>
                    <a className="btn subbtn text-white" onClick={() => submitRating()}>Submit</a>
                    <a className="btn nobtn text-white" onClick={() => setLgShowPast(false)}>No</a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={returnModal}
        onHide={() => setReturnModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="border-0 pb-0">
          <Modal.Title id="example-modal-sizes-title-lg">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0 pt-0 mt-0 return-inner-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center p-0">

                <div className="cancelorder_modal" >
                  <h5 style={{ fontSize: "24px", color: "black" }}>Return Reason</h5>
                  <label className="mt-3" style={{ color: "black", fontWeight: 700 }}>Why are you returning this?</label>
                  <textarea onChange={(review) => setReturnReason(review.target.value)} onKeyDown={(e) => {
                    if (!e.target.value && e.code === "Space") {
                      e.preventDefault();
                    }
                  }} placeholder="Enter a reason..."></textarea>
                  <div className='text-left'>Disclaimer: No refund will be initiated if product is consumed or seal of product is broken.</div>
                  <div class="mb-3 form-check" style={{ "position": 'relative', marginTop: 10 }} >
                    <input type="checkbox" onChange={() => setIsAccepted(!isAccepted)} value={isAccepted} class="form-check-input" id="exampleCheck1" style={{
                      position: 'absolute',
                      left: '20px'
                    }} />
                    <label class="form-check-label" for="exampleCheck1">I agree to terms and conditions</label>
                  </div>
                  <div class="rating-area-btns">
                    <a className="btn subbtn text-white" onClick={() => submitReason()}>Submit</a>
                    <a className="btn nobtn text-white" onClick={() => setReturnModal(false)}>Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* track-order-modal */}
      <Modal show={showTrack} onHide={handleCloseTrack} className='track-order-box-area'>
        <Modal.Header closeButton>
          <Modal.Title>Delivery Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderDetail?.status == "ONWAY" ?
            orderDetail?.tracker != null ?
              <div className="col-md-12 p-0">
                {orderDetail?.tracker?.tracking_details?.length > 0 && orderDetail?.tracker?.tracking_details?.map((item, i) => {
                  // console.log("item---------", item)
                  return (<div className="mb-3 address address1" >
                    <div className="notification_list address-list-area">
                      <span>
                        <div class="form-check pl-0 d-flex align-items-center">
                          {orderDetail?.tracker?.tracking_details[orderDetail?.tracker?.tracking_details?.length - 1]?.message == item?.message ?
                            <input class="form-check-input" checked={true} type="radio" />
                            :
                            <input class="form-check-input" checked={false} type="radio" />
                          }
                          <h5 class="ml-3 mb-0"> {item?.message}  </h5>
                        </div>
                      </span>
                      <h6 className="mb-1 date_1 mt-3">
                        <i className="fa fa-calendar mr-2"></i>
                        {moment(item?.datetime)?.format("MMM DD, YYYY") + " at " + moment(item?.datetime)?.format("hh:mm A")}</h6>
                      <h6 class="d-block date_1">
                        <i className="fa fa-map-marker mr-2 mt-1"></i>
                        {item?.tracking_location?.city?.toLowerCase()}, {item?.tracking_location?.state} {item?.tracking_location?.zip} & United States</h6>
                    </div>
                  </div>
                  )
                })}
              </div>
              :
              null
            : null}
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>



      {/* <Footer /> */}
    </>
  )
}

export default OrderDetails;